import api from "../../axios";

export const dataPayment = (params) => {
  return new Promise((resolve, reject) => {
    console.log(params, "params kirim");
    api
      .get("/api/v1/payment/data-table", {
        params: {
          search: params.search,
          length: params.length,
          page: params.page,
          order: params.order,
          sort: params.sort,
          status: params.status ? params.status : 0,
          type: params.type ? params.type : 0,
          territory_ids: params.territory_ids,
          customer_codes: params.customer_codes
            ? params.customer_codes.includes("All")
              ? undefined
              : params.customer_codes
            : undefined,
          is_form_payment: params.is_form_payment
            ? params.is_form_payment
            : false,
          invoice_codes:
            params.invoice_codes && params.invoice_codes.length > 0
              ? params.invoice_codes
              : undefined,
          methods:
            params.methods && params.methods.length > 0
              ? params.methods
              : undefined,
          is_confirm: params.is_confirm ? params.is_confirm : undefined,
          is_draft: params.is_draft ? params.is_draft : undefined,
        },
      })
      .then((r) => {
        resolve(r);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const dataCustomer = (query = "") => {
  return new Promise((resolve, reject) => {
    api
      .get("/api/v1/payment/customer?search=" + query)
      .then((r) => {
        resolve(r);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getDataCustomer = (params) => {
  return new Promise((resolve, reject) => {
    api
      .get("/api/v1/payment/get-customer", { params })
      .then((r) => {
        resolve(r);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const dataShipTo = (customer_id) => {
  return new Promise((resolve, reject) => {
    api
      .get("/api/v1/master/customer-address/ship-to?customer_id=" + customer_id)
      .then((r) => {
        resolve(r);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const dataPaymentAccount = (code) => {
  return new Promise((resolve, reject) => {
    api
      .get("/api/v1/payment/payment-account?code=" + code)
      .then((r) => {
        resolve(r);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const postPayment = (request) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/v1/payment/store", request)
      .then((r) => {
        resolve(r);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const postPaymentReceive = (request) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/v1/payment/store/receive", request)
      .then((r) => {
        resolve(r);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const postPaymentLine = (request) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/v1/payment/store/line", request)
      .then((r) => {
        resolve(r);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getPaymentLine = (request) => {
  return new Promise((resolve, reject) => {
    api
      .get("/api/v1/payment/line/data-table", { params: request })
      .then((r) => {
        resolve(r);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const postInvoiceDeposit = (request) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/v1/payment/store/inv/deposit", request)
      .then((r) => {
        resolve(r);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const updateInvoiceDeposit = (request) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/v1/payment/update/inv/deposit", request)
      .then((r) => {
        resolve(r);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const deleteInvoiceDeposit = (request) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/v1/payment/delete/inv/deposit", request)
      .then((r) => {
        resolve(r);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const dataInvoiceCustomer = (code, type = 1) => {
  return new Promise((resolve, reject) => {
    api
      .get(`/api/v1/payment/customer/invoice?code=${code}&type=${type}`)
      .then((r) => {
        resolve(r);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const cancelPaymentLine = (request) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/v1/payment/store/line/cancel", request)
      .then((r) => {
        resolve(r);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
